<template>
  <v-container fluid fill-width
  >
    <v-row>
      <v-col
        align="center"
        style="cursor: default;"
      >
        <v-img
          max-height="100"
          max-width="200"
          src="@/assets/images/logo_lg.png"
          :class="'mb-6'"
        />
        <h1 class="mb-2 text--primary">メンテナンス中です</h1>
        <p class="mb-2">いつもQuestImagineeringをご利用いただき誠にありがとうございます。</p>
        <p class="mb-2">現在システムメンテナンスを行なっているため、QuestImagineeringをご利用いただくことができません。</p>
        <p class="mb-9">メンテナンス終了までしばらくお待ちください。</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AppStyleMixin from '@/mixins/AppStyleMixin';
export default {
  name: 'Maintenance',
  mixins: [AppStyleMixin]
};
</script>